import dayjs from "dayjs";

import {
  ARIA,
  BELLAGIO,
  CAESARS_PALACE,
  CROMWELL,
  DURANGO,
  DayTrip,
  HORSESHOE,
  OSHEAS,
  PALMS,
  PARIS,
  PLANET_HOLLYWOOD,
} from "./types";

export const tenTon2024: Array<DayTrip> = [
  [
    dayjs("2024-07-13").valueOf(),
    [DURANGO],
    ["$10 w/ ATS - DNS"],
    [2000],
    [2459],
    [1],
    ["Craps RN"],
    ["Afternoon / didn't lay 8 for all"],
  ],
  [
    dayjs("2024-07-20").valueOf(),
    [CAESARS_PALACE],
    ["$25 & $50 Crapless w/ ATS"],
    [5000],
    [4775],
    [2.5],
    ["Hypeman"],
    ["Night /  hit tall 1x / up early"],
  ],
  [
    dayjs("2024-07-21").valueOf(),
    [CAESARS_PALACE],
    ["$25 Crapless w/ ATS"],
    [4775],
    [5700],
    [2],
    ["Hypeman"],
    ["Night /  hit tall 1x / up early "],
  ],
  [
    dayjs("2024-07-22").valueOf(),
    [PARIS],
    ["$25 Crapless w/ Repeater"],
    [5000],
    [5021],
    [2.25],
    ["Hypeman"],
    ["Put myself in postion to win / hit repeater 2x"],
  ],
  [
    dayjs("2024-07-23").valueOf(),
    [BELLAGIO],
    ["$50 Crapless w/ ATS - DNS"],
    [5000],
    [2301],
    [0.5],
    ["VegasDaytripper"],
    ["Played too big too fast / no bonus hits"],
  ],
  [
    dayjs("2024-07-25").valueOf(),
    [PARIS],
    ["$15 w/ ATS + $25 Crapless w/ Repeater"],
    [5000],
    [0],
    [2],
    ["Wisco330"],
    ["Played from behind the entire night"],
  ],
  [
    dayjs("2024-07-26").valueOf(),
    [PALMS],
    ["$15 w/ ATS - DNS"],
    [3000],
    [5454],
    [2],
    ["Wisco330"],
    ["5 shooters - caught 2 good ones / Hit tall"],
  ],
  [
    dayjs("2024-07-29").valueOf(),
    [PALMS, ARIA],
    ["$15 w/ ATS - DNS", "$25 w/ ATS"],
    [3000, 5000],
    [1075, 7450],
    [2, 2],
    ["GMan", "solo"],
    ["Slow bleed / 18 Shooters at least / No bonus", "~ 30 rolls / hit small"],
  ],
  [
    dayjs("2024-07-31").valueOf(),
    [CROMWELL, OSHEAS, PARIS],
    [
      "$25 w/ ATS and Fire Bet",
      "$15 w/ ATS and Fire Bet",
      "$25 Crapless w/ Repeater",
    ],
    [2000, 2000, 4200],
    [100, 0, 7421],
    [1, 0.45, 2],
    ["Hypeman", "Hypeman", "Hypeman"],
    [
      "3 strikes and out / horrible everything",
      "shot 1x / crowded / potential for greatness",
      "~ 21 rolls / hit repeater 3x",
    ],
  ],
  [
    dayjs("2024-08-01").valueOf(),
    [ARIA, CAESARS_PALACE, PLANET_HOLLYWOOD],
    ["$25 Crapless w/ ATS", "$25 Crapless w/ ATS", "$15 Crapless w/ Repeater"],
    [5000, 10000, 10000],
    [3600, 9500, 9228],
    [0.45, 2, 2],
    ["Hypeman", "Hypeman", "Hypeman"],
    [
      "no magic / nothing working/ should've called it",
      "set up for greatness / (5) couldnt lay for all / shot was on / shouldnt have left",
      "shot 1x / total grind - tired - loved crew",
    ],
  ],
  [
    dayjs("2024-08-07").valueOf(),
    [HORSESHOE],
    ["$15 Crapless w/ Repeater"],
    [10000],
    [5840],
    [1.5],
    ["Hypeman"],
    ["shit show / 15 shooters / 3 strikes / all<10"],
  ],
  [
    dayjs("2024-08-08").valueOf(),
    [PLANET_HOLLYWOOD, PARIS],
    ["$15 w/ ATS", "$15 Crapless w/ Repeater"],
    [9228, 10000],
    [9910, 10615],
    [2.5, 1],
    ["Hypeman", "Byrdman / Hypeman"],
    [
      "bittersweet /  shot 2x/ down big early / ~ 35+ roll 64-63 Set",
      "Byrdman hot hand ~ 20+ rolls /",
    ],
  ],
  [
    dayjs("2024-08-09").valueOf(),
    [PLANET_HOLLYWOOD],
    ["$15 w/ ATS"],
    [9900],
    [16037],
    [2.5],
    ["Byrdman / Hypeman / EnerJing"],
    ["EnerJing hit ATS from straight out / 1x shot decent ~10"],
  ],
  [
    dayjs("2024-08-10").valueOf(),
    [CAESARS_PALACE],
    ["$25 Crapless w/ ATS + $25 w /Repeater"],
    [9500],
    [9545],
    [1],
    ["Byrdman"],
    ["shot 4x / HW set / 19 roller w/9 8's/ shouldve left up 12%"],
  ],
  [
    dayjs("2024-08-11").valueOf(),
    [PLANET_HOLLYWOOD],
    ["$15 w/ ATS"],
    [10000],
    [7000],
    [1.5],
    ["Byrdman / EnerJing"],
    ["shot 2x / too crowded / needed more time to dial in"],
  ],
  [
    dayjs("2024-08-16").valueOf(),
    [CAESARS_PALACE],
    ["$25 w/ATS $25 w /Repeater"],
    [9500],
    [11919],
    [1],
    ["G-Ray"],
    [
      "shot 2x / HW set / 30+ roller hit the repeater 2,5,9,10,12/ should have only bet big on self / table 401 is the shit / Kobayashi activated",
    ],
  ],
  [
    dayjs("2024-08-27").valueOf(),
    [PLANET_HOLLYWOOD, PLANET_HOLLYWOOD],
    ["$25 w/ ATS", "$25 w/ ATS"],
    [10000, 10000],
    [10860, 20235],
    [1.5, 1.5],
    [
      "Justice Jen / Jester / Special Delivery",
      "Special Delivery / Jester / Dean The Machine / Justice Jen",
    ],
    [
      "solid crew to shoot with /shot 1x  ~ 18 / Hit tall / HW set",
      "shot 1x  ~ teens / HW set / needed 3&10 / DTM w/41 roller Made'em All 100$HT and 250H4",
    ],
  ],
  [
    dayjs("2024-08-28").valueOf(),
    [PLANET_HOLLYWOOD, PARIS],
    ["$25 w/ ATS", "$50 w/ ATS"],
    [10000, 10000],
    [11426, 10110],
    [2.5, 0.5],
    ["Hypeman + big crew from the last couple days", "Geedub"],
    [
      "shot 1x very late / poor rol dbl pitched 6-1 HW set / Hype had roll of night made 4k on it just needed 12 for all",
      "shot 1x  ~ teens / HW set / needed 4&12 / late freezing in there had trouble griping the dice / ",
    ],
  ],
  [
    dayjs("2024-08-29").valueOf(),
    [PLANET_HOLLYWOOD],
    ["$25 w/ ATS"],
    [10000],
    [6000],
    [3],
    ["Hypeman / EnerJing / Yo Chef / Jester"],
    [
      "shot 2x / horrible HW + 42-62 / horrorshow / tired stayed too long played hail mary at end was incomplete",
    ],
  ],
  [
    dayjs("2024-08-30").valueOf(),
    [CAESARS_PALACE, ARIA],
    ["$50 w/ Repeater", "$25 w/ ATS"],
    [10000, 10000],
    [9500, 10500],
    [2.5, 2],
    ["solo", "solo"],
    [
      "shot 3x / horrible mutliple sets /  felt great shot just wasnt on 3 strikes and out",
      "shot 6x / glacier cold to start / down big / 2 massive rolls with 64/32 / Hit tall twice",
    ],
  ],
  [
    dayjs("2024-09-02").valueOf(),
    [PLANET_HOLLYWOOD],
    ["$15 w/ ATS"],
    [7950],
    [6303],
    [2.5],
    ["Hypeman/EnerJing"],
    [
      "shot 4x / no set worked / switched tables / grindy grindy mess could never get going / weird energy",
    ],
  ],
  [
    dayjs("2024-09-06").valueOf(),
    [ARIA],
    ["$25 w/ ATS"],
    [5000],
    [13165],
    [1],
    ["The TableMaker"],
    [
      "shot 2x / fav table / starteded solid hit hop 9 with 64 32 / never down / Hit small / stayed for newbie who Hit All / Layed 9 for 24 dice hit a girl's hand and landed on 9 for the big win!",
    ],
  ],
  [
    dayjs("2024-09-07").valueOf(),
    [CAESARS_PALACE, BELLAGIO],
    ["$25 w/ ATS", "$25 w/ ATS"],
    [9500, 5000],
    [6635, 6670],
    [1.5, 1.5],
    ["The TableMaker / Pro Craps / Bougust / Alan G", "The TableMaker"],
    [
      "Table / shot 2x / Looked good just couldn't get over the hump / Perfect scenario 5 shooters / Table cool very nice crew",
      "Shot twice / Second was best HW set / Hit small couldnt close it / Best roll at the table",
    ],
  ],
  [
    dayjs("2024-09-08").valueOf(),
    [ARIA],
    ["$25 w/ ATS"],
    [5000],
    [5011],
    [1],
    ["The TableMaker"],
    ["Shot twice 3x / favorite table / 3 diff sets couldnt get over the hump"],
  ],
  [
    dayjs("2024-09-16").valueOf(),
    [ARIA],
    ["$25 w/ ATS"],
    [10000],
    [10205],
    [1],
    ["Solo"],
    [
      "Shot 1x / great groupd of dealers / HW set / solid roll ~ teens / was tired and grumpy so didnt stay",
    ],
  ],
];
